import React from 'react'
import Layout from '../components/layout'
import driveway from '../images/truck.jpg'
import SEO from '../components/seo'

const Services = () => (
<Layout>
    <SEO title="Services"/>
 <div id="hero" style={{backgroundImage: `linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.2)), url(${driveway})`}}></div>
 <div className="service-page">
<h1>Our Services</h1>
<p>There is no project too big or too small. Our most popular services include:</p>
<ul>
    <li>Stamped Concrete</li>
    <li>Epoxy Flooring and Countertops</li>
    <li>Driveways</li>
    <li>Basements</li>
    <li>Resurfacing and Rejuvination</li>
    <li>Standard Flatwork</li>

</ul>
<p> Don't see what you're looking for? No problem. With over 25 years in the business, we have seen it all. Connect with us and together we will find a way to turn your idea into a completed project.</p>
</div>
</Layout>
)

export default Services
